// Animations
@keyframes pulse {
  0% { box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3); }
  100% { box-shadow: 0 0 0 40px rgba(255, 0, 0, 0); }
}

// Fonts
@font-face {
  font-display: fallback;
  font-family: 'Montserrat Bold';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-bold.woff') format('woff');
  font-display: fallback;
}

* { user-select: none; }

html, body, #app { height: 100%; }
body {
  font-family: "Montserrat Bold", sans-serif;
  min-height: 100%;
  font-size: 16px;
  line-height: 22px;
  background: #000;
  color: #fff;
  overscroll-behavior: none;
}

h1 {
  font-size: 48px;
  line-height: 56px;
}

ol {
  padding: 0 0 0 40px;
  margin: 0;
}

.logo {
  display: block;
  margin: 0 auto;
}

.button {
  font-family: "Montserrat Bold", sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 1.25rem 3rem;
  border: 0;
  background: #221f32;
  color: #fff;
  font-weight: 700;
  margin: 0 auto;
  min-width: 200px;
  text-align: center;
  border-radius: 100px;
  cursor: pointer;
  transition: all .3s;
  &:hover, &:focus {
    @include background-rgba(#221f32, .9);
  }  
}

.button-primary {
  background: #18008b;
  &:hover, &:focus {
    @include background-rgba(#18008b, .9);
  }
}

.button-close {
  width: 60px;
  height: 60px;
  background: url('../img/close.svg') 50% 50% no-repeat transparent;
  border: 0;
  cursor: pointer;
  transition: all .3s;
  position: absolute;
  top: 16px;
  left: 16px;
  &:hover, &:focus {
    opacity: .8;
  }
}
.button-skip {
  height: 60px;
  min-width: 60px;
  padding: 0;
  background: transparent;
  position: absolute;
  border: 0;
  border-radius: 0;
  top: 16px;
  right: 16px;
  &:hover, &:focus {
    background: transparent;
    color: #fff;
  }
}

.button-record {
  width: 140px;
  height: 140px;
  border: 0;
  cursor: pointer;
  background: transparent;
  transition: all .3s;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include background-rgba(#000, .4);
  &:hover, &:focus {
    outline: 0;
    @include background-rgba(#000, .3);
  }
}

.recording {
  @include background-rgba(red, .7);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  animation: pulse 2s infinite;
  &:hover, &:focus {
    outline: 0;
    @include background-rgba(red, .7);
  }
}

.record-icon {
  width: 24px;
  height: 38px;
  background: url('../img/mic.svg') 50% 50% no-repeat transparent;
  display: block;
}

.button-topic {
  margin: .5rem;
}
.button-topic:hover {
  color: #fff;
}

.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  transition: all 1s;
  transition-timing-function: linear;
  @include background-rgba(#000, .1);
}

// Helpers
.container {
  height: 100%;
  position: relative;
  z-index: 10;
  padding-left: 12px;
  padding-right: 12px;
  overflow: auto;
}
.flex { display: flex; }
.block { display: block; }
.flex-column { flex-direction: column; }
.flex-row { flex-direction: row; }
.flex-justify-center { justify-content: center; }
.flex-align-center { align-items: center; }
.col-1-4 { width: 25%; }
.col-1-3 { width: 33.33334%; }
.col-1-2 { width: 50%; }

// General
.flex-wrap { flex-wrap: wrap; }
.text-center { text-align: center; }

// Spacers
.padding-top-0-5rem { padding-top: 0.5rem; }
.padding-top-1rem { padding-top: 1rem; }
.padding-top-2rem { padding-top: 2rem; }
.padding-top-3rem { padding-top: 3rem; }
.padding-top-4rem { padding-top: 4rem; }
.padding-top-5rem { padding-top: 5rem; }
.padding-bottom-0-5rem { padding-bottom: 0.5rem; }
.padding-bottom-1rem { padding-bottom: 1rem; }
.padding-bottom-2rem { padding-bottom: 2rem; }
.padding-bottom-3rem { padding-bottom: 3rem; }

#speech { display: none; }